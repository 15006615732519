<template>
  <div class="top-nav w-full px-32">
    <div class="mobile-bg lg:hidden" :class="menuOpen?'block': 'hidden'"></div>
    
    <div class="p-container h-full flex flex-row justify-between">
        <div class="flex lg:hidden"><router-link @click="linkClick(false, null)" to="/"><img class="absolute left-4 h-12 z-10" src="@/assets/Jade_Spa_Logo_Horizontal.svg" /></router-link></div>
        <div class="flex lg:hidden" @click="linkClick(!menuOpen, null)"><img class="absolute right-4 w-12 h-12 z-10" src="@/assets/menu.svg" /></div>
        <div class="absolute lg:relative left-0 top-0 h-[100vh] lg:h-auto flex w-full py-16 lg:flex" :class="menuOpen?'block': 'hidden'">
          <div class="flex flex-col lg:flex-row w-full mx-auto">
            <div class="hidden lg:flex flex-col justify-center" ><router-link @click="linkClick(false)" to="/"><img class="h-16 mx-auto" src="@/assets/Jade_Spa_Logo_Horizontal.svg" /></router-link></div>
            <div class="flex-1 flex flex-col justify-center" :class="{'underline':currentNav === 'branches'}"><router-link @click="linkClick(false, 'branches')" to="/branches">Branches</router-link></div>
            <div class="flex-1 flex flex-col justify-center" :class="{'underline':currentNav === 'membership'}" ><router-link @click="linkClick(false, 'membership')" to="/membership">Membership</router-link></div>
            <div class="flex-1 flex flex-col justify-center" :class="{'underline':currentNav === 'services'}" ><router-link @click="linkClick(false, 'services')" to="/services">Services</router-link></div>
            <div class="flex-1 flex flex-col justify-center" :class="{'underline':currentNav === 'gift'}" ><router-link @click="linkClick(false, 'gift')" to="/gift">Gift Voucher</router-link></div>
            <div class="flex-1 flex flex-col justify-center" :class="{'underline':currentNav === 'faq'}" ><router-link @click="linkClick(false, 'faq')" to="/faq">FAQs</router-link></div>
            <div class="flex-1 flex flex-col justify-center" :class="{'underline':currentNav === 'contactus'}" ><router-link @click="linkClick(false, 'contactus')" to="/contactus">Contact Us</router-link></div>
            <div class="flex-1 flex flex-col justify-center" :class="{'underline':currentNav === 'gallery'}" ><router-link @click="linkClick(false, 'gallery')" to="/gallery">Gallery</router-link></div>
            <div class="flex-1 flex flex-col justify-center" ><JadeButton @click="linkClick(false, 'booking')" to="/booking">Book Now</JadeButton></div>
          </div>
        </div>
    </div>
  </div>
  <router-view/>
</template>

<script>
import JadeButton from '@/components/JadeButton.vue'
export default {
  name: 'App',
  components:{
    JadeButton,
  },
  data () {
    return {
      menuOpen: false,
      currentNav: null,
    }
  },
  methods: {
    linkClick(menuOpen, currentNav) {
      this.menuOpen = menuOpen;
      this.currentNav = currentNav;
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.top-nav {
  position: absolute;
  top: 0;
  left: 0;
  padding: 30px;
  height: 173px;
  z-index: 20;

  a {
    font-size: 18px;
  }
}

.mobile-bg {
  position: fixed;
  background-color: $colors-brand;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

@media only screen and (min-width: 1920px){
  p {
    font-size: 18px;
  }

  .top-nav {
    padding-left: 16rem;
    padding-right: 16rem;
  }
}

</style>
