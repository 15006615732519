<template>
  <div class="hero w-full relative">
    <div class="p-container w-full h-full flex flex-row">
      <div class="p-content center w-auto h-auto mx-auto my-auto">
        <div class="p-heading">
          We specialized in your relaxing needs
        </div>
        <JadeButton class="mt-4 p-hero-book-now" to="/booking">Book Now</JadeButton>
      </div>
      <div class="scroll-down-for-more absolute bottom-8 lg:bottom-16 w-full text-center">
        Scroll down for more
      </div>
    </div>

  </div>
</template>

<script>
import JadeButton from '@/components/JadeButton.vue'

export default {
  name: 'HomeHero',
  components:{
    JadeButton,
  },
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hero{
  background-image: url(@/assets/Home-Page-BG.jpg);
  background-size: 100% 100%;
  height: 100vh;
}
.p-heading{
  font-family: 'Aliensandcows';
  font-size: 72px;
  color: #d59877;
  letter-spacing: 1.5px;
}

.p-hero-book-now {
  font-size: 24px;
}

@media only screen and (max-width: 720px) {
  .p-heading{
    font-size: 36px;
    letter-spacing: 1px;
  }
}

@media only screen and (min-width: 1920px) {
  .scroll-down-for-more {
    font-size: 24px;
  }
}
</style>
