import axios from 'axios';

const config = {
  headers: {
    "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjQ5MjIxOTk4LCJqdGkiOiIyZDU5MzU0Ni01ZmM5LTRiZmEtODYzYi0yZGMyNDRjZjQyMTAiLCJ1c2VyX3V1aWQiOiJlOTY2OTc3NS0yZGFhLTRjZWYtOTExNC04OTBkNDk5NTU5ODAifQ.eybHwmQr_YAr2c92hpz0yeQkUTaMAYR6DlaDHi5QthM",
    "Content-Type": "application/json", 
  }
};

export const getEventTypes = async () =>
  axios.get(`https://api.calendly.com/event_types?organization=https://api.calendly.com/organizations/e40b1552-ccb5-4fdd-aa4f-650c5c53c306`, config)