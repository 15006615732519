<template>
  <div class="home">
    <HomeHero />
    <HomeAboutUs />
    <HomeBranches />
    <HomeServices />
    <HomeGift />
    <Footer />
    <div ref="scroll" class="scroll-mid-line hidden lg:block"></div>
    <div class="scroll-bottom-line hidden lg:block"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHero from '@/components/HomeHero.vue'
import HomeAboutUs from '@/components/HomeAboutUs.vue'
import HomeBranches from '@/components/HomeBranches.vue'
import HomeServices from '@/components/HomeServices.vue'
import HomeGift from '@/components/HomeGift.vue'
import Footer from '@/components/Footer.vue'
import { getEventTypes } from '@/api/index.js'

export default {
  name: 'HomeView',
  components: {
    HomeHero,
    HomeAboutUs,
    HomeBranches,
    HomeServices,
    HomeGift,
    Footer,
  },
  mounted () {
    let lastKnownScrollPosition = 0;
    let ticking = false;
    let vm = this;
    document.addEventListener('scroll', function() {
      lastKnownScrollPosition = window.scrollY;

       if (!ticking) {
        window.requestAnimationFrame(function() {
          vm.scrollLine(lastKnownScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    });
    getEventTypes().then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
  },
  methods: {
    scrollLine(scrollPos){
      console.log(scrollPos, document.getElementById('app').scrollHeight - window.innerHeight)
      if(scrollPos < (document.getElementById('app').scrollHeight - window.innerHeight)){
        this.$refs.scroll.style.height = (scrollPos - 500) + "px";
      }else{
        this.$refs.scroll.style.height = (scrollPos - 230) + "px";
      }
      
    }
  }
}
</script>
<style scoped lang='scss'>
.home {
  position: relative;
}
.scroll-mid-line {
  width: 2px;
  height: calc(100% - 100vh - 240px);
  background-color: $colors-text;
  position: absolute;
  top: calc(100vh - 50px);
  left: 50%;
  transform: translateX(-50%);
}
.scroll-bottom-line {
  background-color: $colors-text;
  position: absolute;
  height: 2px;
  width: 60%;
  left: 50%;
  bottom: 376px;
  transform: translateX(-50%);
}
</style>
