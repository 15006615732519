<template>
  <div class="footer w-full relative z-10">
    <div class="p-container">
        <div class="p-content pt-20">
            <form class="flex flex-row" ref="form">
                <div class="footer-contact-us flex-1 flex flex-col justify-ends">
                    <div class="footer-heading">contact us</div>
                    <img class="logo" src="@/assets/Jade_Spa_Logo_Vertical.svg"/>
                </div>

                <div class="footer-branches flex-1">
                    <div class="footer-heading text-left">kingston</div>
                    <div class="branch flex flex-col justify-start text-left">
                        <div class="leading-10">Address:</div>
                        <div>146/41 Eastlake Parade,</div>
                        <div>Kingston ACT 2604</div>
                        <div class="mt-2 leading-10">Tel:</div>
                        <div>0499 866 078</div>
                    </div>
                </div>

                <div class="footer-branches flex-1">
                    <div class="footer-heading text-left">Braddon</div>
                    <div class="branch flex flex-col justify-start text-left">
                        <div class="leading-10">Address:</div>
                        <div>G12, 61 Cooyong street,</div>
                        <div>Braddon ACT 2612</div>
                        <div class="mt-2 leading-10">Tel:</div>
                        <div>0438 008 296</div>
                    </div>
                </div>

                <div class="footer-enquiries hidden lg:flex flex-row ">
                    <div class="flex-1 mr-4">
                        <div class="footer-field">
                            <div>*Name</div>
                            <input class="pl-2" name="name" type="text" />
                        </div>
                        <div class="footer-field">
                            <div>*Email</div>
                            <input class="pl-2" name="email" type="text" />
                        </div>
                        <div class="footer-field">
                            <div>*Phone</div>
                            <input class="pl-2" name="phone" type="text"/>
                        </div>
                    </div>
                    <div class="flex flex-col items-end justify-between">
                        <div class="footer-field">
                            <div>*Message</div>
                            <textarea class="pl-2" name="message" />
                        </div>
                        <button class="enquiry-btn" @click="sendEmail">Send</button>
                    </div>
                </div>
            
                <div class="footer-navs flex-1 hidden lg:flex flex-col text-left justify-center">
                    <div class="footer-navs-content mx-auto">
                        <div class="flex-1 flex flex-col justify-center" ><router-link to="/">Home</router-link></div>
                        <div class="flex-1 flex flex-col justify-center" ><router-link to="/booking">Branches</router-link></div>
                        <div class="flex-1 flex flex-col justify-center" ><router-link to="/membership">Membership</router-link></div>
                        <div class="flex-1 flex flex-col justify-center" ><router-link to="/services">Services</router-link></div>
                        <div class="flex-1 flex flex-col justify-center" ><router-link to="/gift">Gift Voucher</router-link></div>
                        <div class="flex-1 flex flex-col justify-center" ><router-link to="/faq">FAQs</router-link></div>
                        <div class="flex-1 flex flex-col justify-center" ><router-link to="/contactus">Contact Us</router-link></div>
                        <div class="flex-1 flex flex-col justify-center" ><router-link to="/gallery">Gallery</router-link></div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="copyright py-8 mt-4">
        Copyright 2022 by Jade Spa Massage All rights reserved
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  name: 'HomeFooter',
  components:{

  },
  props: {
    
  },
  methods: {
      sendEmail() {
        emailjs.sendForm('service_feg2cq9', "template_tw4bv5h", this.$refs.form, "DEd_mkBXoS8Sexu8c")
        .then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        });
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer{
  background-color: $colors-brand;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.footer-heading {
    font-size: 36px;
    font-weight: normal;
    font-family: 'Aliensandcows';
    text-transform: uppercase;
}

.branch {
    line-height: 1;
}

.footer-field {
    text-align: left;
    margin-top: 14px;
   
    input, textarea {
        background-color: $colors-brand;
        border: 1px solid $colors-text;
        border-radius: 10px;
    }

    textarea {
        height: 90px;
    }
}

.enquiry-btn {
    border: 1px solid $colors-text;
    border-radius: 10px;
    width: 100%;
}

.logo {
    max-height: 120px;
}

.footer-navs-content {
    padding-left: 20px;
    border-left: 1px solid $colors-text;
}

.footer-branches {
    div {
        letter-spacing: 1px;
    }
}

.copyright {
    letter-spacing: 1px;
}
@media only screen and (max-width: 720px) {
    .footer {
        padding: 0px 12px;
    }
    .footer-heading {
        font-size: 24px;
        font-weight: normal;
        font-family: 'Aliensandcows';
        text-transform: uppercase;
    }

    .branch {
        font-size: 14px;
        line-height: 1;
    }

    .copyright {
        font-size: 14px;
    }
}
</style>
