/* eslint-disable vue/multi-word-component-names */
import { createApp } from 'vue'
import {Tabs, Tab} from 'vue3-tabs-component';
import App from './App.vue'
import router from './router'
import './index.css'
import './styles/main.scss';

createApp(App).component('tabs', Tabs)
.component('tab', Tab).use(router).mount('#app')
