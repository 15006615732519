<template>
  <div class="home-gift w-full relative">
    <div class="p-container flex flex-col h-full">
      <div class="p-heading flex flex-col lg:flex-row">
        <div class="flex-1 text-right flex flex-col ml-4 lg:ml-0 lg:mr-10 items-start lg:items-end my-0 h-full mt-16 lg:mt-32">
          gift voucher
        </div>
        <div class="text-left flex flex-col items-start flex-1">
        </div>
      </div>
      <div class="p-content w-full flex flex-col lg:flex-row z-10 relative">
        <div class="flex-1 text-left lg:text-right flex flex-col items-center lg:items-end my-0 h-full lg:pr-12">
          <div class="content-left p-4 pt-0 lg:pl-12">
            <p class="content-intro text-left lg:text-right">
              buying our physical gift voucher
            </p>
            <p>
              Please contact us or walk-in to our shop for buying physical gift voucher.
            </p>
            <p>
              Bulk purchase of 5+ vouchers in a transaction are eligible for further discount (in-store only)
            </p>
            <JadeButton class="view-btn" to="/gift">Learn more</JadeButton>
           </div>
        </div>
        <div class="text-left flex flex-col items-start flex-1">
          <div class="content-right">
          </div>  
        </div>
      </div>
      <img class="gift absolute hidden lg:flex" src="@/assets/gift.png" />
    </div>
  
  <img class="hidden lg:block absolute mb-20 top-32 right-1/2 lg:-mb-40 w-12 h-12" src="@/assets/icon-left.svg" />
  </div>
</template>

<script>
import JadeButton from '@/components/JadeButton.vue'

export default {
  name: 'HomeGift',
  components:{
    JadeButton,
  },
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home-gift{
  background-color: $colors-brand;
  height: 100vh;
  width: 100%;
}

.p-heading {
  div {
    font-family: 'Aliensandcows';
  }
  font-size: 42px;
  line-height: 1;

  & > .flex-1 {
    padding-right: 50px;
  }
}

.content-left {
  max-width: 720px;
  padding-right: 50px;

  p {
    font-size: 18px;
    margin-top: 20px;
  }

  .content-intro {
    margin-top: 0px;
    margin-bottom: 50px;
  }
}

.content-right {
  max-width: 426px;
  margin-top: 50px;

  p:not(:first) {
    margin-top: 20px;
  }
}

.content-heading{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 18px;
}

.view-btn{
  margin-top: 20px;
}

.gift {
  width: 100%;
  bottom: 0%;
  right: 0;
  pointer-events: none;
  z-index: 5;
}
</style>
