<template>
  <div class="home-aboutus w-full relative overflow-hidden">
    <div class="p-container flex flex-col h-full">
      <div class="p-heading flex flex-row">
        <div class="flex-1 text-right flex flex-col items-end my-0 h-full">
          
        </div>
        <div class="about-us-block text-left relative lg:absolute lg:top-40 lg:left-1/2 flex flex-col items-start flex-1 lg:ml-16">
          <div class="about-us-heading ">about us</div>
          <div class="who-we-are mt-1">who we are</div>
        </div>
      </div>
      <div class="p-content w-full my-auto flex flex-col lg:flex-row">
        <div class="relative z-10 p-4 flex-1 text-left flex flex-col items-center lg:items-end">
          <div class="content-left">
            <p>
              Jade Spa Massage is a professional Chinese & Taiwanese Massage centre with qualified staffs who have extensive massage knowledge and experience in Taiwan, China, and Australia.
            </p>
            <p>
              We have a group of dedicated professional massage therapist, combining of western and oriental skill, to provide you the best possible treatment.
            </p>
            <p>
              We believed your satisfaction is our top priority and our service is tailored to optimize your service experience.
            </p>
            <p>
              We hope our professional masseurs will provide exceptional and effective experiences to our clients.
            </p>
            <p class="font-bold">
              Jade Spa Massage accepted all of Health Fund providers.
            </p>
           </div>
        </div>
        <div class="content-right flex-1">
        </div>
      </div>
    </div>
    <img class="hands hidden lg:block  absolute mb-20 lg:-mb-40" src="@/assets/Jade_Spa_Hands_v2.png" />
    <img class="hidden lg:block absolute mb-20 top-40 left-1/2 lg:-mb-40 w-12 h-12" src="@/assets/icon-right.svg" />
  </div>
</template>

<script>

export default {
  name: 'HomeAboutUs',
  components:{
    
  },
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home-aboutus{
  background-image: url(@/assets/Jade_Spa_Hands_BG.jpg);
  background-size: auto 100%;
  width: 100%;
  height: 100vh;
}

.content-left {
  max-width: 80%;
  padding-bottom: 200px;
  padding-right: 24px;

  p {
    margin-top: 20px;
  }
}
.about-us-block {

}
.about-us-heading {
  
  font-family: 'Aliensandcows';
  font-size: 42px;
  line-height: 1;
}
.who-we-are {
  font-size: 16px;
}
.hands {
  width: 100%;
  bottom: calc(100vh - 100% - 100px);
  left: 50%;
  transform: translateX(-48%);
  pointer-events: none;
  z-index: 5;
}

@media only screen and (min-width: 1920px) {
  .content-left {
    padding-right: 64px;
  }
  .p-content {
    
  }
  
  .about-us-heading {
    margin-left: -8px;
  }

  .who-we-are {
    margin-left: -8px;
  }

  .hands {
    bottom: calc(100vh - 100% - 200px);
  }
}
</style>
