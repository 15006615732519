<template>
  <div class="home-branches w-full relative overflow-hidden">
    <div class="p-container flex flex-col h-full">
      <div class="p-heading flex flex-row">
        <div class="flex-1 text-right flex flex-col ml-4 lg:ml-0 items-start lg:items-end my-0 h-full mt-16 lg:mt-20 lg:mr-32">
            branches
        </div>
        <div class="text-left flex flex-col items-start flex-1">
        </div>
      </div>
      <div class="relaitve z-10 p-content w-full flex flex-col lg:flex-row">
        <div class="flex-1 text-right flex flex-col items-end my-0 h-full p-4 pt-0">
          <div class="content-left pr-0 lg:pr-12">
            <p class="content-intro text-right mb-16 lg:mb-36">
              get more info about our branches
            </p>
           </div>
        </div>
        <div class="p-4 pt-6 text-left flex flex-col items-start flex-1">
          <div class="content-right pl-0 lg:pl-12 mt-0 slg:mt-16">
            <h3 class="content-heading">
              Kingston
            </h3>
            <p>
              Jade spa massage Kingston branch combines beauty and massage services, our beauticians all have qualified professional certificates and will provide perfect services according to your needs.
            </p>
            <JadeButton class="view-btn" to="/booking?branch=kingston">View</JadeButton>
          </div>  
        </div>
      </div>
    </div>
    <img class="hidden lg:block absolute mb-20 top-20 right-1/2 lg:-mb-40 w-12 h-12" src="@/assets/icon-left.svg" />
  <div class="spiral hidden lg:flex w-4/5 absolute bottom-0 pointer-events-none">
    <img src="@/assets/Jade_Spa_Spiral_Shadow.png" />
    <div class="spiral-bg"></div>
  </div>
  </div>
</template>

<script>
import JadeButton from '@/components/JadeButton.vue'

export default {
  name: 'HomeBranches',
  components:{
    JadeButton,
  },
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home-branches{
  background-color: $colors-brand;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.p-heading {

  div {
    font-family: 'Aliensandcows';
  }
  font-size: 42px;
  text-transform: uppercase;
  line-height: 1;
}

.content-left {
  max-width: 521px;

  p {
    font-size: 16px;
    margin-top: 8px;
  }

  .content-intro {
    margin-top: 0px;
  }
}

.content-right {
  max-width: 426px;

  p:not(:first) {
    margin-top: 20px;
  }
}

.content-heading{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
  letter-spacing: 1px;
}

.view-btn{
  margin-top: 20px;
}

.spiral {
  max-width: min(50vw, 800px);
  left: 50%;
  transform: translate(-48%, 25%);
  z-index: 5;
  
  img {
    position: relative;
    z-index: 20;
  }
}

.spiral-bg {
  width: 100%;
  height: 640px;
  bottom: 0%;
  position: absolute;
  background-color: $colors-brand;
  z-index: 10;
}
</style>
