<template>
  <div class="home-services w-full relative">
    <div class="p-container flex flex-col h-full">
      <div class="p-heading flex flex-col lg:flex-row">
        <div class="flex-1 text-right flex flex-col items-end my-0 h-full">
          
        </div>
        <div class="text-left flex flex-col items-start flex-1 ml-4 lg:ml-32 lg:mt-16">
          <div class="our-serivce">our services</div>
          <div class="what-we-do">what we do</div>
        </div>
      </div>
      <div class="p-content w-full flex flex-col lg:flex-row my-auto">
        <div class="flex-1 text-right flex flex-col items-end">
          <div class="content-left">
          </div>
        </div>
        <div class="p-4 lg:pl-12 text-left flex flex-col items-center lg:items-start flex-1">
          <div class="content-right">
            <div class="p-service">
              <h4>Relaxation massage</h4>
              <p class="lg:whitespace-nowrap">
                General relaxation, relieving muscle tension and improving blood circulation
              </p>
            </div>
            <div class="p-service">
              <h4>Deep tissue massage</h4>
              <p class="lg:whitespace-nowrap">
                A deep tissue massage brings you these benefits but also helps you physically <br/>in terms of aches and stiffness.
              </p>
            </div>
            <div>
              <h4>Remedial massage</h4>
              <p class="lg:whitespace-nowrap">
                Hand on, evidence-based approach to treating soft tissue injuries/conditions <br/>and postural imbalances.
              </p>
            </div>
            <JadeButton class="view-btn" to="/services">Learn more</JadeButton>
          </div>  
        </div>
      </div>
       <img class="hidden lg:block absolute mb-20 top-28 left-1/2 lg:-mb-40 w-12 h-12" src="@/assets/icon-right.svg" />
    </div>
    
  </div>
</template>

<script>
import JadeButton from '@/components/JadeButton.vue'

export default {
  name: 'HomeServices',
  components:{
    JadeButton,
  },
  props: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home-services{
  background-image: url(@/assets/Our-Service-Page-BG.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}
.p-heading {
  font-size: 42px;
  margin-top: 50px;
  line-height: 1;
}
.our-serivce {
  font-family: 'Aliensandcows';
}
.what-we-do {
  font-size: 16px;
  font-family: 'AcuminVariableConcept';
  text-transform: none;
}

.content-right {
  max-width: 426px;
  padding-left: 10px;

  p:not(:first) {
    margin-top: 20px;
  }

  h4{
    font-size: 18px;
    font-weight: bold;
  }
}
.content-heading{
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 18px;
}
.p-service {
  margin-bottom: 18px;
}
.view-btn{
  margin-top: 20px;
}
</style>
