<template>
  <div class="jade-button" :class="{'justify-ends items-end': alignRight}" @click='handleClick'>
    <router-link v-if="to" class="j-button px-6 py-2 whitespace-nowrap" :to="to">
      <slot v-if="!text"></slot>
       <span v-else>{{ text  }}</span>
    </router-link>
    <button v-else class="j-button px-6 py-2">
      <slot v-if="!text"></slot>
       <span v-else>{{ text }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'JadeButton',
  emits: ["click"],
  props: {
    to: String,
    text: String,
    alignRight: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit("click");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.j-button {
  width: auto;
  height: auto;
  border: 1px solid $colors-text;
  border-radius: 10px;
}
</style>
